<template>
<div class="content_container matchingsuggest">
    <div class="inner">
        <!--상품 카테고리 시작-->
        <div class="box_category">
            <!-- <div class="category">
                <p>전체</p>
                <img src="@/assets/images/main/icon_category_00.svg" alt="icon_category_00"/>
                <img src="@/assets/images/main/icon_category_00_w.svg" alt="icon_category_00" />
            </div> -->
            <div class="category on" @click="categoryCheck($event, 0)">
                <p>{{this.basicData.category[0].content}}</p>
                <img src="@/assets/images/main/icon_category_02.svg" alt="icon_category_02"/>
                <img src="@/assets/images/main/icon_category_02_w.svg" alt="icon_category_02" />
            </div>
            <div class="category" @click="categoryCheck($event, 1)">
                <p>{{this.basicData.category[1].content}}</p>
                <img src="@/assets/images/main/icon_category_01.svg" alt="icon_category_01"/>
                <img src="@/assets/images/main/icon_category_01_w.svg" alt="icon_category_01" />
            </div>
            <div class="category" @click="categoryCheck($event, 2)">
                <p>{{this.basicData.category[2].content}}</p>
                <img src="@/assets/images/main/icon_category_03.svg" alt="icon_category_03"/>
                <img src="@/assets/images/main/icon_category_03_w.svg" alt="icon_category_03" />
            </div>
            <div class="category" @click="categoryCheck($event, 3)">
                <p>{{this.basicData.category[3].content}}</p>
                <img src="@/assets/images/main/icon_category_04.svg" alt="icon_category_04"/>
                <img src="@/assets/images/main/icon_category_04_w.svg" alt="icon_category_04" />
            </div>
            <div class="category" @click="categoryCheck($event, 4)">
                <p>{{this.basicData.category[4].content}}</p>
                <img src="@/assets/images/main/icon_category_05.svg" alt="icon_category_05"/>
                <img src="@/assets/images/main/icon_category_05_w.svg" alt="icon_category_05" />
            </div>
            <div class="category" @click="categoryCheck($event, 5)">
                <p>{{this.basicData.category[5].content}}</p>
                <img src="@/assets/images/main/icon_category_06.svg" alt="icon_category_06"/>
                <img src="@/assets/images/main/icon_category_06_w.svg" alt="icon_category_06" />
            </div>
        </div>

        <div class="text">
            <p>
                <span class="category_name">"{{this.basicData.category[this.categoryIndex].content}}"</span>에 <span class="num_seller">{{this.basicData.category[this.categoryIndex].seller_count}}</span>명의 판매자가 있습니다.
            </p>
            <p>전부 제안하시겠습니까?</p>
        </div>
        <!--상품 카테고리 끝-->

        <!--제안 시작-->
        <div class="suggest_wrap fill">
            <div class="fill_explain">
                <h1>요청사항 <span class="star">*</span></h1>
                <textarea v-model="suggestion" placeholder="ex) 뷰티상품의 모든 판매자님, 제 상품에 대해서 긴급제안을 드립니다. 구매가능하신 분들은 응해주세요."></textarea>
            </div>
            <div class="product_wrap">
                <div class="product" v-for="(item,i) in this.basicData.product" :key="i">
                    <input type="radio" :id="`product${i}`" name="product"/>
                    <label :for="`product${i}`" @click="this.product_pk = item.product_pk">
                        <div class="image_box">
                            <img v-if="item.original_name" :src="item.path + item.save_name" :alt="item.original_name" />
                            <div v-else>이미지가 없습니다</div>
                        </div>
                        <p>{{item.name}}</p>
                    </label>
                </div>
                <div v-if="this.basicData.product.length == 0">제안할 상품이 없습니다.</div>
            </div>

            <div class="token_wrap">
                <div class="scale_wrap">
                    <div class="scale">
                        <h1>최소 사용 토큰</h1>
                        <p><span>{{this.numberFormat(this.basicData.category[this.categoryIndex].min_token)}}</span>개</p>
                    </div>
                    <div class="scale">
                        <h1>총 사용 토큰</h1>
                        <p><span>{{this.numberFormat(this.getTotalToken(this.meta_token))}}</span>개</p>
                    </div>
                </div>
                <div class="add_token_wrap">
                    <div class="num_token">
                        <h2>보유 토큰</h2>
                        <p><span>{{this.numberFormat(this.user.meta_token)}}</span>개</p>
                    </div>
                    <div class="add_token">
                        <button class="minus" @click="changeMetaToken('-', $event)">
                            -
                        </button>
                        <div class="fill_number">
                            <h2>사용할 토큰</h2>
                            <input type="text" :value="meta_token" @input="changeMetaToken('=', $event)"/>
                            <p><span>X {{this.basicData.category[this.categoryIndex].seller_count}}</span> 개</p>
                        </div>
                        <button class="plus" @click="changeMetaToken('+', $event)">
                            +
                        </button>
                    </div>
                </div>
                <button class="sub_button" @click="this.$router.push('/mypage/MypageBuyToken')">토큰충전</button>
            </div>
        </div>

        <button type="button" id="apply" class="common_button"
          @click="apply" disabled
        >제안하기</button>
        <!--제안 끝-->
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { fetchData } from '@/utils/fetchData'
import { postData } from '@/utils/postData'
import { numberFormat } from '@/utils/numberFormat'

export default {
  mixins : [ fetchData, postData, numberFormat ],
  computed : {
    ...mapState('user', ['user']),
    ...mapState('access_token', ['access_token'])
  },
  data(){
    return {
      categoryIndex : 0,
      suggestion : '',
      product_pk : '',
      meta_token: 0, // 사용할 메타토큰

      isFetched : false,

      basicData : {
        category : [
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
          {
            category_pk: 1,
            content: "패션",
            min_token: 70,
            seller_count: 2,
          },
        ],
        product : []
      }
    }
  },
  watch : {
    // 사용 메타토큰 변경시 apply버튼의 disable 속성 다시 계산
    meta_token(val){
      if(val < this.basicData.category[this.categoryIndex].min_token || val == 0){
        document.getElementById('apply').disabled = true;
      } else {
        document.getElementById('apply').disabled = false;
      }
    },
    // 카테고리 변경시 최소사용토큰 변하기 때문에 apply버튼의 disable 속성 다시 계산
    categoryIndex(){
      if(this.meta_token < this.basicData.category[this.categoryIndex].min_token || this.meta_token == 0){
        document.getElementById('apply').disabled = true;
      } else {
        document.getElementById('apply').disabled = false;
      }
      if ( this.getTotalToken(this.meta_token) > this.user.meta_token) {
        this.meta_token = Math.floor(this.user.meta_token / this.basicData.category[this.categoryIndex].seller_count);
      } 
    }
  },
  created() {
    this.fetchBasicData();
  },
  methods : {
    fetchBasicData(){
      this.fetchData('/getBasicSuggest', (data)=>{
        this.basicData = data;
      })
    },
    // 카테고리 선택
    categoryCheck(event, i) {
      const categories = document.querySelectorAll(".matchingsuggest .box_category .category");
      categories.forEach((el) => el.classList.remove("on"));
      event.currentTarget.classList.add("on");

      this.categoryIndex = i;
    },
    getTotalToken(val){
      return val*this.basicData.category[this.categoryIndex].seller_count;
    },
    changeMetaToken(sign, event){
      // 10-> 0 -> 20으로 바꿀 때 포인터가 맨 뒤로가는거 방지 (replace처리 막기)
      if(sign == '=' && event.target.value == 0 ){
        this.meta_token = 0;
        event.target.value = 0;
        return;
      }
      event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

      if (sign == "-") {
        if (this.meta_token >= 1) {
          --this.meta_token;
        }
      } else if (sign == "+") {
        if (this.user.meta_token >= this.getTotalToken(this.meta_token + 1) ) {
          ++this.meta_token;
        }
      } else {
        if (event.target.value < 0 || event.target.value=="") {
          event.target.value = 0;
        } else if ( this.getTotalToken(event.target.value) > this.user.meta_token) {
          event.target.value = Math.floor(this.user.meta_token / this.basicData.category[this.categoryIndex].seller_count);
          this.meta_token = event.target.value*1;
        } else {
          this.meta_token = event.target.value*1;
        }
      }
    },
    // 제안하기
    apply(){
      if(!this.suggestion){
        this.$store.commit('alert', '요청사항을 입력해주세요');
      } else if(!this.product_pk){
        this.$store.commit('alert', '제안할 상품을 선택해주세요');
      } else if(this.meta_token < this.basicData.category[this.categoryIndex].min_token ){
        this.$store.commit('alert', '최소토큰 이상 사용해주세요');
      } else if(this.getTotalToken(this.meta_token) > this.user.meta_token ){
        this.$store.commit('alert', '총 사용 토큰을 확인해주세요')
      } else {
        this.postData('/saveAllSuggest', {
          meta_token: this.meta_token,
          category_pk: this.basicData.category[this.categoryIndex].category_pk,
          product_pk: this.product_pk,
        }, ()=>{
          this.$store.commit('alert' ,'제안이 완료되었습니다.');
          this.$store.commit('user/metaTokenUse', this.meta_token)
          this.$router.push('/mypage/RequestProvider')
        })
      }
    },
  }
}
</script>

<style scoped src="@/assets/css/layout_suggest.css">
</style>